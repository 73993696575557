import React from "react";
import Iframe from "react-iframe";

const JotFormIframe = () => {
  const jotFormUrl = "https://form.jotform.com/241057245336150"; // Replace with your actual JotForm form URL

  return (
    <Iframe
      url={jotFormUrl}
      width="100%" // Ensures responsiveness
      height="900px" // Adjust height as needed
      frameborder="0"
      scrolling="yes"
      allowtransparency="true"
      allow="geolocation; microphone; camera; fullscreen"
      title="Resume Information Gathering Form"
    />
  );
};

export default JotFormIframe;
