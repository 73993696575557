import React from "react";
import JotFormIframe from "./JotFormIframe";

function App() {
  return (
    <div>
      {/* Your other content */}
      <JotFormIframe />
    </div>
  );
}

export default App;
